exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-order-tsx": () => import("./../../../src/pages/order.tsx" /* webpackChunkName: "component---src-pages-order-tsx" */),
  "component---src-pages-product-cephalopods-baby-octopus-tsx": () => import("./../../../src/pages/product/cephalopods/baby-octopus.tsx" /* webpackChunkName: "component---src-pages-product-cephalopods-baby-octopus-tsx" */),
  "component---src-pages-product-cephalopods-baby-squid-tsx": () => import("./../../../src/pages/product/cephalopods/baby-squid.tsx" /* webpackChunkName: "component---src-pages-product-cephalopods-baby-squid-tsx" */),
  "component---src-pages-product-cephalopods-cuttlefish-tsx": () => import("./../../../src/pages/product/cephalopods/cuttlefish.tsx" /* webpackChunkName: "component---src-pages-product-cephalopods-cuttlefish-tsx" */),
  "component---src-pages-product-cephalopods-index-tsx": () => import("./../../../src/pages/product/cephalopods/index.tsx" /* webpackChunkName: "component---src-pages-product-cephalopods-index-tsx" */),
  "component---src-pages-product-cephalopods-octopus-tsx": () => import("./../../../src/pages/product/cephalopods/octopus.tsx" /* webpackChunkName: "component---src-pages-product-cephalopods-octopus-tsx" */),
  "component---src-pages-product-cephalopods-squid-tsx": () => import("./../../../src/pages/product/cephalopods/squid.tsx" /* webpackChunkName: "component---src-pages-product-cephalopods-squid-tsx" */),
  "component---src-pages-product-crustaceans-bamboo-lobster-tsx": () => import("./../../../src/pages/product/crustaceans/bamboo-lobster.tsx" /* webpackChunkName: "component---src-pages-product-crustaceans-bamboo-lobster-tsx" */),
  "component---src-pages-product-crustaceans-brown-lobster-tsx": () => import("./../../../src/pages/product/crustaceans/brown-lobster.tsx" /* webpackChunkName: "component---src-pages-product-crustaceans-brown-lobster-tsx" */),
  "component---src-pages-product-crustaceans-index-tsx": () => import("./../../../src/pages/product/crustaceans/index.tsx" /* webpackChunkName: "component---src-pages-product-crustaceans-index-tsx" */),
  "component---src-pages-product-crustaceans-mud-crab-tsx": () => import("./../../../src/pages/product/crustaceans/mud-crab.tsx" /* webpackChunkName: "component---src-pages-product-crustaceans-mud-crab-tsx" */),
  "component---src-pages-product-crustaceans-rock-lobster-tsx": () => import("./../../../src/pages/product/crustaceans/rock-lobster.tsx" /* webpackChunkName: "component---src-pages-product-crustaceans-rock-lobster-tsx" */),
  "component---src-pages-product-fish-asian-seabass-tsx": () => import("./../../../src/pages/product/fish/asian-seabass.tsx" /* webpackChunkName: "component---src-pages-product-fish-asian-seabass-tsx" */),
  "component---src-pages-product-fish-index-tsx": () => import("./../../../src/pages/product/fish/index.tsx" /* webpackChunkName: "component---src-pages-product-fish-index-tsx" */),
  "component---src-pages-product-fish-red-grouper-tsx": () => import("./../../../src/pages/product/fish/red-grouper.tsx" /* webpackChunkName: "component---src-pages-product-fish-red-grouper-tsx" */),
  "component---src-pages-product-fish-red-snapper-tsx": () => import("./../../../src/pages/product/fish/red-snapper.tsx" /* webpackChunkName: "component---src-pages-product-fish-red-snapper-tsx" */),
  "component---src-pages-product-fish-yellowfin-tuna-tsx": () => import("./../../../src/pages/product/fish/yellowfin-tuna.tsx" /* webpackChunkName: "component---src-pages-product-fish-yellowfin-tuna-tsx" */),
  "component---src-pages-product-prawns-giant-prawn-tsx": () => import("./../../../src/pages/product/prawns/giant-prawn.tsx" /* webpackChunkName: "component---src-pages-product-prawns-giant-prawn-tsx" */),
  "component---src-pages-product-prawns-index-tsx": () => import("./../../../src/pages/product/prawns/index.tsx" /* webpackChunkName: "component---src-pages-product-prawns-index-tsx" */),
  "component---src-pages-product-prawns-tiger-prawn-tsx": () => import("./../../../src/pages/product/prawns/tiger-prawn.tsx" /* webpackChunkName: "component---src-pages-product-prawns-tiger-prawn-tsx" */),
  "component---src-pages-product-prawns-vannamei-prawn-tsx": () => import("./../../../src/pages/product/prawns/vannamei-prawn.tsx" /* webpackChunkName: "component---src-pages-product-prawns-vannamei-prawn-tsx" */),
  "component---src-pages-product-sea-cucumber-black-teat-fish-sea-cucumber-tsx": () => import("./../../../src/pages/product/sea-cucumber/black-teat-fish-sea-cucumber.tsx" /* webpackChunkName: "component---src-pages-product-sea-cucumber-black-teat-fish-sea-cucumber-tsx" */),
  "component---src-pages-product-sea-cucumber-greenfish-sea-cucumber-tsx": () => import("./../../../src/pages/product/sea-cucumber/greenfish-sea-cucumber.tsx" /* webpackChunkName: "component---src-pages-product-sea-cucumber-greenfish-sea-cucumber-tsx" */),
  "component---src-pages-product-sea-cucumber-index-tsx": () => import("./../../../src/pages/product/sea-cucumber/index.tsx" /* webpackChunkName: "component---src-pages-product-sea-cucumber-index-tsx" */),
  "component---src-pages-product-sea-cucumber-leopard-sea-cucumber-tsx": () => import("./../../../src/pages/product/sea-cucumber/leopard-sea-cucumber.tsx" /* webpackChunkName: "component---src-pages-product-sea-cucumber-leopard-sea-cucumber-tsx" */),
  "component---src-pages-product-sea-cucumber-sea-cucumber-tsx": () => import("./../../../src/pages/product/sea-cucumber/sea-cucumber.tsx" /* webpackChunkName: "component---src-pages-product-sea-cucumber-sea-cucumber-tsx" */),
  "component---src-pages-product-sea-cucumber-white-teatfish-sea-cucumber-tsx": () => import("./../../../src/pages/product/sea-cucumber/white-teatfish-sea-cucumber.tsx" /* webpackChunkName: "component---src-pages-product-sea-cucumber-white-teatfish-sea-cucumber-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */)
}

